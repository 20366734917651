<template>
  <div class="award_page">
    <section class="section1">
      <div class="title">听说，羽素又双叒叕搞了永乐宫？</div>
      <div class="info">
        <img src="@/assets/images/banzhuan/yonglegong/yonglegong1.jpg" alt="" />
        <div class="desc">
          <div class="fz23">
            2020年8月14日，羽素与众上集团举行了签约仪式<br />
            永乐宫和羽素正式官宣啦！
          </div>
          <div class="fz18 mt50">
            羽素品牌总经理王笠成先生和众上集团CEO何廷华女士等大牛都纷纷隆重出席<br />
            活动现场。其中，众上集团作为IP界运营大佬，有着丰富资源和资深的运营能力。
          </div>
        </div>
      </div>
    </section>
    <section class="section2">
      <div class="info">
        <div class="fz18 mt50">
          羽素品牌总监王笠成先生表示：对双方这次深度合作非常<br />
          期待相信一定能碰出前所未有的火花。
        </div>
      </div>
      <img src="@/assets/images/banzhuan/yonglegong/yonglegong2.jpg" alt="" />
    </section>
    <section class="section3">
      <div class="title">国潮当道，迈向品牌新高度</div>
      <div class="desc fz18">
        <div class="mt40">
          羽素这次选取了国潮作为主要的设计风格，希望把传统文化跟产品完美结合，之所以会选择国潮，是因为作为国货品牌的羽素，与中国文化有着
          惊人的相似之处，都比较低调内敛。<br />
          我们希望能把产品做到用内涵打动人，让大家能在了解羽素的过程中，就像了解中国优秀传统文化一样，循序渐进，源远流长，就像品美酒，慢慢品尝，
          到最后回味悠长。<br />
          在未来，羽素将会推出各种联名产品，各工作人员都在加紧研发，力求将产品做到"专业+有趣"两不误。<br />
        </div>
      </div>
      <div class="img">
        <img src="@/assets/images/banzhuan/yonglegong/yonglegong3.jpg" alt="" />
      </div>
    </section>
    <section class="section4">
      <div class="title">跨界联合，品牌视觉全新升级</div>
      <div class="section4_content">
         <div class="fz18">
          永乐宫来自北京中创文旅文化产业集团有限公司的国朝IP，中创文旅是行业领先的
          文旅IP运营管理公司，运营有三山五园文化、中国航天等多个知名IP，其中永乐宫建
          立于元朝，有着非常悠久的历史文化哦。
        </div>
        <img src="@/assets/images/banzhuan/yonglegong/yonglegong5.jpg" alt="" class="mt30" />
      </div>
      <div class="fz18 mt30">
          最值得pick的是它家的东方笔画，牌面上完全不输敦煌壁画，用笔可讲究了，继承了唐、宋的绘画技巧，又融合了元代的绘画特点，让永乐宫的壁画
          成为了元代瑰宝，看着一幅幅精致的笔画，真的是太可了。
        </div>
      <div class="img">
        <img src="@/assets/images/banzhuan/yonglegong/yonglegong4.jpg" alt="" />
      </div>
    </section>
    <section class="section5">
      <div class="title">联合品1：羽素舒缓修护喷雾</div>
      <div class="desc fz18">
        <div class="mt40">
          不仅蕴含加拿大冰川水进口成分，还拥有维敏休、<br />
SymCalmin和甘油磷脂肌醇胆碱盐专利成分，<br />
能帮助敏感肌快速缓解红、肿、痒、痛等症状，<br />
还能起到抗炎、修护屏障的效果。<br />
        </div>
      </div>
    </section>
    <section class="section6">
      <div class="title">联合品2：羽素联名珍藏礼盒</div>
      <div class="desc fz18">
        <div class="mt40">
          胶态硫和纤连蛋白CP组合，蕴含8%美国凡特鲁斯定制胶态硫<br />
和3%医药纤连蛋白成分，强效消炎杀菌，调理油脂的同时，<br />
还能提升肌肤抵抗力。<br />
        </div>
      </div>
    </section>
    <div class="title mt120">
      民族精粹跟现代潮品的碰撞，必定会点燃非一般的火花<br />
和我一起期待，未来羽素品牌将给我们带来更多惊喜！
    </div>
  </div>
</template>

<script>
export default {
  name: 'Expert',
  components: {},
}
</script>
<style scoped lang='scss'>
.award_page {
  width: 1450px;
  margin: 0 auto;
  line-height: 1.5;
  .title {
    font-size: 30px;
    text-align: center;
  }
  .section1 {
    background: #fffbf4;
    .title {
      padding: 90px 0;
    }
    .info {
      display: flex;
      line-height: 1.8;
      img {
        width: 650px;
        height: 340px;
      }
    }
    .desc {
      flex: 1;
      padding-left: 80px;
      display: flex;
      align-content: center;
      justify-content: center;
      flex-direction: column;
    }
  }
  .section2 {
    display: flex;
    padding-top: 20px;
    .info {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .section3 {
    background: #f6f7f7;
    line-height: 2;
    padding-top: 80px;
    img {
      width: 750px;
      height: 530px;
      margin: 60px auto 50px;
    }
    .desc {
      padding: 0 70px 0 100px;
    }
  }
  .section4{
    background: #fffbf4;
    overflow: hidden;
    padding: 0 80px 60px 100px;
    position: relative;
    .title{
      text-align: left;
      margin: 50px 0 50px 0;
    }
    .section4_content{
      width: 700px;
    }
    .img{
      position: absolute;
      right: 70px;
      top: 70px;
      width: 427px;
      height: 400px;
      img{
        width: 100%;
        height: 100%;
      }
    }
  }
  .section5 {
    background: url('~@/assets/images/banzhuan/award/award2.jpg');
    height: 580px;
    background-size: 100%;
    padding-left: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .title {
      text-align: left;
    }
  }
  .section6{
    background: url('~@/assets/images/banzhuan/yonglegong/yonglegong7.jpg') no-repeat left 0;
    height: 800px;
    background-size: 600px auto;
    padding-left: 800px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .title{
      text-align: left;
    }
  }
  .img {
    text-align: center;
  }
  .fz23 {
    font-size: 23px;
  }
  .fz18 {
    font-size: 18px;
  }
  .mt30 {
    margin-top: 30px;
  }
  .mt40 {
    margin-top: 40px;
  }
  .mt50 {
    margin-top: 50px;
  }
  .mt60 {
    margin-top: 60px;
  }
  .tc {
    text-align: center;
  }
  .mt120{
    margin: 120px auto;
  }
}
</style>
