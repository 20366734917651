<template>
  <div class="star_page">
    <div class="star_page_tip">
      <p>*以下为各平台假冒售卖羽素产品页面截图</p>
      <p>
        羽素官方对于侵权(<span class="red">假冒伪劣、盗图、不正规售卖等</span
        >)行为，将严惩必究
      </p>
    </div>
    <ul class="star_page_item">
      <li v-for="(item, index) in imgList" :key="index">
        <img :src="item" alt="" @click="bigImg(index)" />
      </li>
    </ul>
    <div class="mask_block" v-if="isBlock">
      <img :src="imgUrl" alt="" />
      <div class="close" @click="handClose">x</div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import img1 from "../assets/images/star/1.jpg";
import img2 from "../assets/images/star/2.jpg";
import img3 from "../assets/images/star/3.jpg";
import img4 from "../assets/images/star/4.jpg";
import img5 from "../assets/images/star/5.jpg";
import img6 from "../assets/images/star/6.jpg";
import img7 from "../assets/images/star/7.jpg";
import img8 from "../assets/images/star/8.jpg";
import img9 from "../assets/images/star/9.jpg";
import img10 from "../assets/images/star/10.jpg";
import img11 from "../assets/images/star/11.jpg";
import img12 from "../assets/images/star/12.jpg";
import img13 from "../assets/images/star/13.jpg";
import img14 from "../assets/images/star/14.jpg";
import img15 from "../assets/images/star/15.jpg";
import img16 from "../assets/images/star/16.jpg";
import img17 from "../assets/images/star/17.jpg";
import img18 from "../assets/images/star/18.png";
import img19 from "../assets/images/star/19.jpg";
import img20 from "../assets/images/star/20.png";
import img21 from "../assets/images/star/21.jpg";
import img22 from "../assets/images/star/22.jpg";
import img23 from "../assets/images/star/23.jpg";

import img24 from "../assets/images/star/24.png";
import img25 from "../assets/images/star/25.jpg";
import img26 from "../assets/images/star/26.jpg";
import img27 from "../assets/images/star/27.jpg";
import img28 from "../assets/images/star/28.jpg";
import img29 from "../assets/images/star/29.jpg";

import img30 from "../assets/images/star/30.jpg";
import img31 from "../assets/images/star/31.jpg";
import img32 from "../assets/images/star/32.jpg";
import img33 from "../assets/images/star/33.jpg";
import img34 from "../assets/images/star/34.jpg";
import img35 from "../assets/images/star/35.jpg";

import img36 from "../assets/images/star/36.jpg";
import img37 from "../assets/images/star/37.jpg";
import img38 from "../assets/images/star/38.jpg";
import img39 from "../assets/images/star/39.jpg";
import img40 from "../assets/images/star/40.jpg";
import img41 from "../assets/images/star/41.jpg";

import img42 from "../assets/images/star/42.jpg";
import img43 from "../assets/images/star/43.jpg";
import img44 from "../assets/images/star/44.jpg";
import img45 from "../assets/images/star/45.jpg";
import img46 from "../assets/images/star/46.jpg";
import img47 from "../assets/images/star/47.jpg";
import img48 from "../assets/images/star/48.jpg";
import img49 from "../assets/images/star/49.jpg";
import img50 from "../assets/images/star/50.jpg";
import img51 from "../assets/images/star/51.jpg";
import img52 from "../assets/images/star/52.jpg";
import img53 from "../assets/images/star/53.jpg";

import img54 from "../assets/images/star/54.jpg";
import img55 from "../assets/images/star/55.jpg";
import img56 from "../assets/images/star/56.jpg";
import img57 from "../assets/images/star/57.jpg";
import img58 from "../assets/images/star/58.jpg";
import img59 from "../assets/images/star/59.jpg";

import img60 from "../assets/images/star/60.jpg";
import img61 from "../assets/images/star/61.jpg";
import img62 from "../assets/images/star/62.jpg";
import img63 from "../assets/images/star/63.jpg";
import img64 from "../assets/images/star/64.jpg";
import img65 from "../assets/images/star/65.jpg";
import img66 from "../assets/images/star/66.jpg";
import img67 from "../assets/images/star/67.jpg";
import img68 from "../assets/images/star/68.jpg";
import img69 from "../assets/images/star/69.jpg";

import img70 from "../assets/images/star/70.jpg";
import img71 from "../assets/images/star/71.jpg";
import img72 from "../assets/images/star/72.jpg";
import img73 from "../assets/images/star/73.jpg";
import img74 from "../assets/images/star/74.jpg";
import img75 from "../assets/images/star/75.jpg";
import img76 from "../assets/images/star/76.jpg";
import img77 from "../assets/images/star/77.jpg";
import img78 from "../assets/images/star/78.jpg";
import img79 from "../assets/images/star/79.jpg";

import img80 from "../assets/images/star/80.jpg";
import img81 from "../assets/images/star/81.jpg";
import img82 from "../assets/images/star/82.jpg";
import img83 from "../assets/images/star/83.jpg";
import img84 from "../assets/images/star/84.jpg";
import img85 from "../assets/images/star/85.jpg";
import img86 from "../assets/images/star/86.jpg";

import img87 from "../assets/images/star/87.jpeg";
import img88 from "../assets/images/star/88.jpeg";
import img89 from "../assets/images/star/89.jpeg";
import img90 from "../assets/images/star/90.jpeg";
import img91 from "../assets/images/star/91.jpeg";
import img92 from "../assets/images/star/92.jpeg";
import img93 from "../assets/images/star/93.jpeg";
import img94 from "../assets/images/star/94.jpeg";
import img95 from "../assets/images/star/95.jpeg";
import img96 from "../assets/images/star/96.jpeg";
import img97 from "../assets/images/star/97.jpeg";
import img98 from "../assets/images/star/98.jpeg";
import img99 from "../assets/images/star/99.jpg";
import img100 from "../assets/images/star/100.jpg";
import img101 from "../assets/images/star/101.jpg";
import img102 from "../assets/images/star/102.jpg";
import img103 from "../assets/images/star/103.png";
import img104 from "../assets/images/star/104.png";
import img105 from "../assets/images/star/105.png";
import img106 from "../assets/images/star/106.png";
import img107 from "../assets/images/star/107.jpg";
import img108 from "../assets/images/star/108.png";
import img109 from "../assets/images/star/109.png";
import img110 from "../assets/images/star/110.png";
import img111 from "../assets/images/star/111.jpg";
import img112 from "../assets/images/star/112.jpg";
import img113 from "../assets/images/star/113.png";
import img114 from "../assets/images/star/114.jpg";
import img115 from "../assets/images/star/115.jpg";
import img116 from "../assets/images/star/116.png";
import img117 from "../assets/images/star/117.png";
import img118 from "../assets/images/star/118.png";
import img119 from "../assets/images/star/119.png";
import img120 from "../assets/images/star/120.png";
import img121 from "../assets/images/star/121.png";
import img122 from "../assets/images/star/122.jpg";
import img123 from "../assets/images/star/123.jpg";
import img124 from "../assets/images/star/124.jpg";
import img125 from "../assets/images/star/125.jpg";
import img126 from "../assets/images/star/126.jpg";
import img127 from "../assets/images/star/127.jpg";
import img128 from "../assets/images/star/128.jpg";
import img129 from "../assets/images/star/129.jpg";
import img130 from "../assets/images/star/130.png";
import img131 from "../assets/images/star/131.jpg";
import img132 from "../assets/images/star/132.png";
import img133 from "../assets/images/star/133.png";
import img134 from "../assets/images/star/134.png";
import img135 from "../assets/images/star/135.png";
import img136 from "../assets/images/star/136.png";
import img137 from "../assets/images/star/137.png";
import img138 from "../assets/images/star/138.png";
import img139 from "../assets/images/star/139.png";
import img140 from "../assets/images/star/140.png";
import img142 from "../assets/images/star/142.png";
import img143 from "../assets/images/star/143.png";
import img144 from "../assets/images/star/144.png";
import img145 from "../assets/images/star/145.png";
import img146 from "../assets/images/star/146.png";
import img147 from "../assets/images/star/147.png";
import img148 from "../assets/images/star/148.png";
import img149 from "../assets/images/star/149.png";
import img150 from "../assets/images/star/150.png";
import img151 from "../assets/images/star/151.png";
import img152 from "../assets/images/star/152.png";
import img153 from "../assets/images/star/153.png";
import img154 from "../assets/images/star/154.png";
import img155 from "../assets/images/star/155.png";
import img156 from "../assets/images/star/156.png";
import img157 from "../assets/images/star/157.png";
import img158 from "../assets/images/star/158.png";
import img159 from "../assets/images/star/159.jpg";
import img160 from "../assets/images/star/160.png";
import img161 from "../assets/images/star/161.png";
import img162 from "../assets/images/star/162.png";
import img163 from "../assets/images/star/163.png";
import img164 from "../assets/images/star/164.png";
import img165 from "../assets/images/star/165.png";
import img166 from "../assets/images/star/166.png";
import img167 from "../assets/images/star/167.png";
import img168 from "../assets/images/star/168.png";
import img169 from "../assets/images/star/169.jpg";
import img170 from "../assets/images/star/170.jpg";
import img171 from "../assets/images/star/171.png";
import img172 from "../assets/images/star/172.png";
import img173 from "../assets/images/star/173.png";
import img174 from "../assets/images/star/174.png";
import img175 from "../assets/images/star/175.png";
import img176 from "../assets/images/star/176.png";
import img177 from "../assets/images/star/177.jpg";
import img178 from "../assets/images/star/178.jpg";
import img179 from "../assets/images/star/179.png";
import img180 from "../assets/images/star/180.png";
import img181 from "../assets/images/star/181.jpg";
import img182 from "../assets/images/star/182.jpg";
import img183 from "../assets/images/star/183.jpg";
import img184 from "../assets/images/star/184.jpg";
import img185 from "../assets/images/star/185.jpg";
import img186 from "../assets/images/star/186.png";

export default {
  name: "talent",

  data: () => {
    return {
      isBlock: false,
      imgUrl: null,
      imgList: [
        img1,
        img2,
        img3,
        img4,
        img5,
        img6,
        img7,
        img8,
        img9,
        img10,
        img11,
        img12,
        img13,
        img14,
        img15,
        img16,
        img17,
        img18,
        img19,
        img20,
        img21,
        img22,
        img23,
        img24,
        img25,
        img26,
        img27,
        img28,
        img29,

        img30,
        img31,
        img32,
        img33,
        img34,
        img35,

        img36,
        img37,
        img38,
        img39,
        img40,
        img41,

        img42,
        img43,
        img44,
        img45,
        img46,
        img47,
        img48,
        img49,
        img50,
        img51,
        img52,
        img53,
        img54,
        img55,
        img56,
        img57,
        img58,
        img59,

        img60,
        img61,
        img62,
        img63,
        img64,
        img65,
        img66,
        img67,
        img68,
        img69,

        img70,
        img71,
        img72,
        img73,
        img74,
        img75,
        img76,
        img77,
        img78,
        img79,

        img80,
        img81,
        img82,
        img83,
        img84,
        img85,
        img86,

        img87,
        img88,
        img89,
        img90,
        img91,
        img92,
        img93,
        img94,
        img95,
        img96,
        img97,
        img98,

        img99,
        img100,
        img101,
        img102,

        img103,
        img104,
        img105,
        img106,
        img107,
        img108,
        img109,
        img110,
        img111,
        img112,
        img113,
        img114,
        img115,
        img116,
        img117,
        img118,
        img119,
        img120,
        img121,
        img122,
        img123,
        img124,
        img125,
        img126,
        img127,
        img128,
        img129,
        img130,
        img131,
        img132,
        img133,
        img134,
        img135,
        img136,
        img137,
        img138,
        img139,
        img140,
        img142,
        img143,
        img144,
        img145,
        img146,
        img147,
        img148,
        img149,
        img150,
        img151,
        img152,
        img153,
        img154,
        img155,
        img156,
        img157,
        img158,
        img159,
        img160,
        img161,
        img162,
        img163,
        img164,
        img165,
        img166,
        img167,
        img168,
        img169,
        img170,
        img171,
        img172,
        img173,
        img174,
        img175,
        img176,
        img177,
        img178,
        img179,
        img180,
        img181,
        img182,
        img183,
        img184,
        img185,
        img186,
      ],
    };
  },
  methods: {
    bigImg(index) {
      this.imgUrl = this.imgList[index];
      this.isBlock = true;
    },
    handClose() {
      this.isBlock = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.star_page {
  text-align: center;
  .star_page_tip {
    font-size: 0.13rem;
    margin: 5% 0;
    p {
      color: #3e3a39;
      line-height: 40px;
    }
  }
  .red {
    color: #e60012;
  }
  .star_page_item {
    width: 92%;
    padding: 0 4%;
    display: flex;
    justify-content: start;
    flex-flow: wrap;
    li {
      width: 30%;
      list-style: none;
      background: #f7f8f8;
      box-shadow: 5px 5px 2px #e3e2e2;
      padding: 50px;
      box-sizing: border-box;
      margin-bottom: 2%;
      margin-right: 5%;
      &:nth-child(3n) {
        margin-right: 0;
      }
      img {
        width: 100%;
        display: block;
        margin: 0 auto;
        border: 2px solid #f6e344;
        cursor: pointer;
      }
    }
  }
  .mask_block {
    position: fixed;
    top: 0;
    height: 0;
    width: 100%;
    height: 100%;
    background: rgba($color: #000000, $alpha: 0.7);
    z-index: 10001;
    img {
      height: 80%;
      display: block;
      margin: 5% auto 0;
    }
    .close {
      border: 5px solid #ccc;
      width: 40px;
      height: 40px;
      line-height: 36px;
      text-align: center;
      border-radius: 50%;
      font-size: 40px;
      font-weight: 100;
      position: fixed;
      top: 5%;
      right: 20%;
      color: #ccc;
      cursor: pointer;
    }
  }
  @media screen and (max-width: 1500px) {
    .close {
      right: 10.5% !important;
    }
  }
  @media screen and (max-width: 1200px) {
    .mask_block {
      img {
        height: 60% !important;
        margin: 15% auto 0;
      }
    }
  }
}
</style>
